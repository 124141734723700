import * as util from './utility';
import newRollbar from './rollbar';
import { setupSpreedly } from './credit_card';
import * as Ladda from 'ladda';
import { gatherDonorInfo, clearGeoLocationFields, onRegistrationNewPage, onUpdateDonorPage, onNewDonationRegisteredPage, onOnlineGivingNewPage, displayFeedback } from './donor_info';
import { finalStepBankAccount } from './bank_account';

let l;

window.addEventListener('DOMContentLoaded', _e => {
  if (!document.getElementById('donation_button')) {
    return;   // Nothing to process
  }
  $('#addressfields').hide();

  l = Ladda.create(document.querySelector('#donation_button'));

  setupSpreedly();

  // Locations/Causes onChange section
  $('#donor_location_id').change(_e => lookupCauses());
  $("#donation_cause_id").change(_e => toggleFrequency());
  $('#change-location').click(_e => showLocationControls());
  $('#donation_donor_attributes_postal_code').on('change', () => {
    return delay(() => {
      return searchUsingPostalCode();
    }, 1000);
  });
  $('#donation_location_id').change(_e => consultLocations($('#donation_location_id').val()));
  $('#donor_postal_code').on('change', () => {
      return delay(() => {
        return searchUsingPostalCode();
      }, 1000);
  });

  $('#donation_button').click(_e => {
    l.start();

    if (validateDonor()) {
      if (onNewDonationRegisteredPage()) {  // Donor already registered
        sendNewDonationRegisteredData();
        return true;
      } else if (!onNewDonationRegisteredPage() && onOnlineGivingNewPage() && !document.getElementById('card_number')) {
        // User is not registered, but he/she already has a PaymentMethod
        sendDonorWithPaymentMethod();
      } else {
        if ($('#credit_card_fields').is(':visible')) {
          Spreedly.validate();
        }

        if ($('#bank_account_fields').is(':visible')) {
          finalStepBankAccount();
        }
      }
    }

    return false;
  });
});

const sendDonorWithPaymentMethod = () => {

  const donorInfo = gatherDonorInfo(false, true);

  const fullData = {donation: {amount: $('#donation_amount').val(),
                               frequency: $('#donation_frequency').val(),
                               cause_id: $('#donation_cause_id').val(),
                               location_id: $('#donation_location_id').val(),
                               donor_id: $('#donor-info').data().donorToken,
                               organization_id: $('#organization-info').data().organizationToken },
                    donor_attributes: {
                      email_address: donorInfo.email_address,
                      first_name: donorInfo.first_name,
                      last_name: donorInfo.last_name,
                      street_address_1: donorInfo.street_address_1,
                      city: donorInfo.city,
                      state_province: donorInfo.state_province,
                      postal_code: donorInfo.city,
                      country: donorInfo.country,
                    }};

  $.ajax({
    url: '/api/v2/donations',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data: JSON.stringify(fullData),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    timeout: 15000
  }).done((data) => {
    Ladda.stopAll();
    return displayFeedback(data);
  }).fail((jqXHR, textStatus) => {
    Ladda.stopAll();
    let message;
    if (textStatus === 'timeout') {
        message = { text: util.t('javascript.credit_cards.alerts.timeout'), error: true };
    } else {
        message = { text: util.t('javascript.credit_cards.alerts.something_went_wrong'), error: true };
    }
    newRollbar.error('Error while submitting payment info', {'Error status': textStatus, 'Transaction data': fullData});
    return displayFeedback(message);
  });
};

const sendNewDonationRegisteredData = () => {

  let fullData = {donation: {amount: $('#donation_amount').val(),
                             cause_id: $('#donation_cause_id').val(),
                             location_id: $('#donation_location_id').val(),
                             donor_id: $('#donor-info').data().donorToken,
                             organization_id: $('#organization-info').data().organizationToken } };

  $.ajax({
    url: '/api/v2/donations',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data: JSON.stringify(fullData),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    timeout: 15000
  }).done((data) => {
    Ladda.stopAll();
    return displayFeedback(data);
  }).fail((jqXHR, textStatus) => {
    Ladda.stopAll();
    let message;
    if (textStatus === 'timeout') {
        message = { text: util.t('javascript.credit_cards.alerts.timeout'), error: true };
    } else {
        message = { text: util.t('javascript.credit_cards.alerts.something_went_wrong'), error: true };
    }
    newRollbar.error('Error while submitting payment info', {'Error status': textStatus, 'Transaction data': fullData});
    return displayFeedback(message);
  });
};

const validateDonor = () => {
  if (onUpdateDonorPage()) { return true; }

  let allLabels = document.getElementsByClassName('mb-3 row');
  for (let lbl of allLabels) { lbl.classList.remove('requiredField'); }

  const donor = gatherDonorInfo(false);

  if (!onRegistrationNewPage()) {
    if (!donor.donation_amount.val()) {
      displayFeedback({text: 'Please enter a valid amount', error: true});
      donor.donation_amount.focus();
      donor.donation_amount.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
  }

  if (donor.cause_id && !donor.cause_id.val()) {
    displayFeedback({text: 'Please select a location and a cause', error: true});
    donor.cause_id.focus();
    donor.cause_id.closest('div.mb-3.row').addClass('requiredField');
    Ladda.stopAll();
    return false;
  }
  // if the payment method is already defined, bank_account_fields div doesn't exist
  if (document.getElementById('bank_account_fields') && $('#bank_account_fields').is(':visible')) {
    if (!$('#account_authorization').is(':checked')) {
      displayFeedback({text: 'You must authorize your Bank Account info', error: true});
      Ladda.stopAll();
      return false;
    }
    if (!$('#payment_method_token').val()) {
      displayFeedback({text: 'Please provide your Bank Account info clicking "Select"', error: true});
      Ladda.stopAll();
      return false;
    }
  }
  // Donor already registered, we don't need to validate the rest of the fields
  if (onUpdateDonorPage() || onNewDonationRegisteredPage()) {
    return true;
  }

  if (donor.first_name.val().length < 2) {
    displayFeedback({text: 'Please enter a first name ', error: true});
    donor.first_name.focus();
    donor.first_name.closest('div.mb-3.row').addClass('requiredField');
    Ladda.stopAll();
    return false;
  }
  if (donor.last_name.val().length < 2) {
    displayFeedback({text: 'Please enter a last name ', error: true});
    donor.last_name.focus();
    donor.last_name.closest('div.mb-3.row').addClass('requiredField');
    Ladda.stopAll();
    return false;
  }
  if (donor.email_address.val().length < 5) {
    displayFeedback({text: 'Please enter an email', error: true});
    donor.email_address.focus();
    donor.email_address.closest('div.mb-3.row').addClass('requiredField');
    Ladda.stopAll();
    return false;
  }
  if (document.getElementById('donation_donor_attributes_street_address_1') && $('#donation_donor_attributes_street_address_1').is(':visible')) {
    if (donor.street_address_1.val().length < 2) {
      displayFeedback({text: 'Please enter an address', error: true});
      donor.street_address_1.focus();
      donor.street_address_1.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
    if (donor.postal_code.val().length < 3) {
      displayFeedback({text: 'Please enter a valid postal code', error: true});
      donor.postal_code.focus();
      donor.postal_code.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
    if (donor.city.val().length < 1) {
      displayFeedback({text: 'Please enter a last name', error: true});
      donor.city.focus();
      donor.city.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
    if (donor.state_province.val().length < 2) {
      displayFeedback({text: 'Please enter a state or province', error: true});
      donor.state_province.focus();
      donor.state_province.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
    if (donor.country.val().length < 2) {
      displayFeedback({text: 'Please enter a country', error: true});
      donor.country.focus();
      donor.country.closest('div.mb-3.row').addClass('requiredField');
      Ladda.stopAll();
      return false;
    }
  }

  return true;
};

const clearLocation = () => {
  clearGeoLocationFields();
  return $('#city_state_country').text(util.t('javascript.donations.errors.postal.code.not.found'));
};

const delay = (() => {
  let timer = 0;
  return (callback, ms) => {
    clearTimeout(timer);
    return timer = setTimeout(callback, ms);
  };
})();

// Build Organization's active causes
const lookupCauses = () => {
  const locationId = $('#donor_location_id').val();
    if (!locationId) {
      return;
    }

    setTimeout(() => {
      consultLocations(locationId);
    }, 500);
};

const consultLocations = (locationId) => {
  if (!locationId) { return; }
  let spinner = $('#spinning');
  spinner.show();

  let causeSelectInput = onRegistrationNewPage() ? 'donor_cause_id' : 'donation_cause_id';

  const organization_id = document.getElementById('organization-info').dataset.organizationToken;
  const donor_token = $('#donor-info').data().donorToken;

  const path = `/organizations/${organization_id}/donations/causes_for_location`;

  $.get(path, {location_id: locationId, id: donor_token}, (data) => {
    let causeSelect;
    if (document.getElementById(causeSelectInput)) {
      causeSelect = document.getElementById(causeSelectInput);
      causeSelect.length = 0;
    } else {
      causeSelect = document.createElement('select');
      causeSelect.name = 'donor[cause_id]';
      causeSelect.id = causeSelectInput;
      causeSelect.className = 'form-control';
    }
    causeSelect.disabled = false;
    let option;
    // Include a blank option
    option = document.createElement('option');
    option.value = 0;
    option.text = 'Select';
    causeSelect.appendChild(option);

    data.forEach(cause => {
      option = document.createElement('option');
      option.value = cause.id;
      option.text = cause.name;
      option.dataRec = { "recurring-giving-enabled": cause.prefers_recurring_giving };
      causeSelect.appendChild(option);
    });
    let label;
    if (document.getElementById('label_causes_id')) {
      label = document.getElementById('label_causes_id');
    } else {
      label = document.createElement('label');
      label.innerHTML = 'Causes';
      label.htmlFor = 'causes';
      label.id = 'label_causes_id';
      label.className = 'control-label';
    }

    if (causeSelectInput == 'donor_cause_id') {
      return document.getElementById('causes_container').appendChild(label).appendChild(causeSelect);
    } else {
      return true;
    }
  }).fail(() => {
    return alert("Error JSON API loading Causes");
  }).done(() => {
    return spinner.hide();
  });
};

// CAN: M6G1C5
// US: 37203
const googleApiUrl = (postalCode) => {
  return "https://maps.googleapis.com/maps/api/geocode/json?components=country:US|country:CAN|postal_code:" + postalCode + "&key=AIzaSyAM29Rgm3c6F4rz7kj1kicV084hNdXnvOg";
  };

const searchUsingPostalCode = () => {
  let spinner = $('#spinning');
  spinner.show();
  clearLocation();
  const donor = gatherDonorInfo(false);
  const postalCode = donor.postal_code.val();
  if (postalCode.length < 3) {
    return clearLocation();
  }
  return $.ajax({
    url: googleApiUrl(postalCode),
    type: 'GET',
    success: (data) => {
      if (data.results.length > 0) {
        let address = data.results[0].address_components;
        address.forEach(objekt => {
          if (objekt.types.includes('locality')) {
            donor.city.val(objekt.long_name);
          } else if (objekt.types.includes('neighborhood')) {
            donor.city.val(objekt.long_name);
          } else if (objekt.types.includes('administrative_area_level_1')) {
            donor.state_province.val(objekt.short_name);
          } else if (objekt.types.includes('country')) {
            donor.country.val(objekt.short_name);
          }
        });

        const country = donor.country.val();
        const city    = donor.city.val();
        if (country !== 'US' && country !== 'CA' && country !== 'PR') {
          clearLocation();
          return showLocationControls();
        } else if (!city) {
          clearLocation();
          return showLocationControls();
        } else {
          return populateLocation();
        }
      } else {
        clearLocation();
        return showLocationControls();
      }
    },
    error: (data) => {
      // console.log('Postal Code Error >> ', data);
      return clearLocation();
    },
    complete: () =>  spinner.hide(),
  });
};

const showLocationControls = () => {
  $('#location_info').show();
  return $("#addressfields").show();
};

const toggleFrequency = () => {
  const RgE = document.getElementById('donation_cause_id').selectedOptions[0].dataset.recurringGivingEnabled;
  if (RgE == 'false') {
    $('#donation_frequency').attr('disabled', true);  // this cause disables the recurring-giving option
    return $('#frequency_div').hide();
  }

  const causeId = parseInt($('#donation_cause_id').val());
  if (causeId) { $('#donation_cause_id').closest('div.mb-3.row').removeClass('requiredField'); }
  const organizationToken = document.getElementById('organization-info').dataset.organizationToken;
  const URL = `/organizations/${organizationToken}/donation_recurrences.json`;

  $.get(URL, (data) => {
    if (data.length == 0) {
      $('#donation_frequency').attr('disabled', false);
      return $('#frequency_div').show();
    } else {
      let Ids = data.map(cause => cause.id);
      if (Ids.includes(causeId)) {
        $('#donation_frequency').attr('disabled', true);
        return $('#frequency_div').hide();
      } else {
        $("#donation_frequency").attr('disabled', false);
        return $("#frequency_div").show();
        }
      }
    }).fail(() => {
      return newRollbar.error('Error checking for existence of donation recurrence for Cause ' + causeId);
    });
};

// Display the Google Geocode results and a link to change those fields
const populateLocation = () => {
  const donor = gatherDonorInfo(false);
  let city    = donor.city.val();
  let state   = donor.state_province.val();
  let country = donor.country.val();

  $('#city_state_country').removeClass('text-danger');
  $("#city_state_country").text(_.compact([city, state, country]).join(', '));
  return $('#change-location').show().text(util.t('shared.change'));
};
